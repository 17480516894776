import { Application } from "@hotwired/stimulus"

require("jquery")
require("popper.js")
require("bootstrap")
require("bootstrap-select")
require("chartkick/chart.js")
require("moment")
require("daterangepicker")
require("js-cookie")

require("../lib/naics-code")
require("../lib/number-formatter")

window.clone = require('lodash.clone');
window.isEmpty = require('lodash.isempty');
window.isEqual = require('lodash.isempty');
window.snakeCase = require('lodash.snakecase');
window.startCase = require('lodash.startcase');
window.uniq = require('lodash.uniq');

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
