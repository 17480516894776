import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    pagination: Object,
    upper: Number,
    lower: Number
  }

  reload({ detail }) {
    if (isEqual(this.paginationValue, detail.meta.pagination)) {
      this.render()
    } else {
      this.paginationValue = detail.meta.pagination
    }
  }

  paginationValueChanged() {
    this.setUpperBound()
    this.setLowerBound()
    this.render()
  }

  firstPage() {
    let html = '';

    if (this.paginationValue.total_count && this.paginationValue.current_page > 1) {
      html = this.buildPageItem('First', 1)
    }

    return html;
  }

  prevPage() {
    return this.buildPageItem('Prev', this.paginationValue.prev_page)
  }

  nextPage() {
    return this.buildPageItem('Next', this.paginationValue.next_page)
  }

  currentPage() {
    return this.buildPageItem(this.paginationValue.current_page, this.paginationValue.current_page)
  }

  lastPage() {
    return this.buildPageItem('Last', this.paginationValue.total_pages)
  }

  setUpperBound() {
    let i;

    if (this.paginationValue.total_pages < 6) {
      i = this.paginationValue.total_pages
    } else {
      i = this.paginationValue.current_page + 2

      if (i < 5) {
        i = 5
      } else if (i > this.paginationValue.total_pages) {
        i = this.paginationValue.total_pages
      }
    }

    this.upperValue = i
  }

  setLowerBound() {
    let i

    if (this.paginationValue.total_pages < 6) {
      i = 1
    } else {
      i = this.paginationValue.current_page - 2

      if (i < 1) {
        i = 1
      } else if ((this.upperValue - i) > 5) {
        i = this.upperValue - 5
      }
    }

    this.lowerValue = i
  }

  pages() {
    let html = ''

    for(var i = this.lowerValue; i <= this.upperValue; i++) {
      let options = {
        'active': this.paginationValue.current_page == i
      }

      html = html.concat(this.buildPageItem(i, i, options))
    }

    return html
  }

  buildPageItem(label, page, options) {
    options = options || {}

    let action = options.action
    let active = options.active || false
    let html = ''

    if (label && page) {
      html =  `
        <li class="page-item ${active ? 'active' : ''}">
          <a class="page-link" href="#" data-paginator-page-param="${page}" data-action="click->paginator#page">${label}</a>
        </li>
      `
    }

    return html
  }

  page(event) {
    if (event) {
      event.preventDefault()
    }

    let page = event.params.page

    this.dispatch("page", {
      detail: {
        page: page
      }
    })
  }

  render() {
    let html

    if (jQuery.isEmptyObject(this.paginationValue) || this.paginationValue.total_pages == 1) {
      html = ''
    } else {
      html = `
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            ${this.firstPage()}
            ${this.prevPage()}
            ${this.pages()}
            ${this.nextPage()}
            ${this.lastPage()}
          </ul>
        </nav>
      `
    }

    this.element.innerHTML = html
  }
}
