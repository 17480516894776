import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['picker', 'startDate', 'endDate']

  static values = {
    'startDate': String,
    'endDate': String,
    'daterange': Object
  }

  initialize() {
    if (Cookies.get('daterange')) {
      this.daterangeValue = JSON.parse(Cookies.get('daterange'))
    }
  }

  connect() {
    $(this.pickerTarget).daterangepicker({
      locale: {
        format: 'M/D/YYYY'
      }
    }, this.changed.bind(this));
  }

  changed(start, end, label) {
    this.startDateValue = start.format('YYYY-MM-DD');
    this.endDateValue = end.format('YYYY-MM-DD');

    this.daterangeValue = Object.assign(this.daterangeValue, {
      start_date: this.startDateValue,
      end_date: this.endDateValue
    })

    this.dispatch('changed', {
      detail: {
        startDate: this.startDateValue,
        endDate: this.endDateValue,
        label: label
      }
    });
  }

  startDateValueChanged() {
    if (this.hasStartDateTarget) {
      $(this.startDateTarget).val(this.startDateValue);
    }
  }

  endDateValueChanged() {
    if (this.hasEndDateTarget) {
      $(this.endDateTarget).val(this.endDateValue);
    }
  }

  daterangeValueChanged() {
    if (!isEmpty(this.daterangeValue))
      Cookies.set('daterange', JSON.stringify(this.daterangeValue), { sameSite: 'strict' });
  }

  show() {
    this.pickerTarget.focus();
  }
}
