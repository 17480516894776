import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    pagination: Object,
    lower: Number,
    upper: Number
  };

  reload({ detail }) {
    if (isEqual(this.paginationValue, detail.meta.pagination)) {
      this.render()
    } else {
      this.paginationValue = detail.meta.pagination
    }
  }

  paginationValueChanged() {
    this.setLowerBound();
    this.setUpperBound();
    this.render();
  }

  setLowerBound() {
    let i;

    if (this.paginationValue.total_pages == 1) {
      i = 1;
    } else if (this.paginationValue.total_pages > 1) {
      i = ((this.paginationValue.current_page - 1) * this.paginationValue.limit) + 1;
    }

    this.lowerValue = i;
  }

  setUpperBound() {
    let i = this.paginationValue.current_page * this.paginationValue.limit;

    if (i > this.paginationValue.total_count) {
      i = this.paginationValue.total_count;
    }

    this.upperValue = i;
  }

  render() {
    let html;

    if (this.paginationValue.total_pages == 0) { // No results
      html = '';
    } else if (this.paginationValue.total_pages == 1) {
      html = `Displaying all <strong>${this.upperValue}</strong> results`
    } else if (this.paginationValue.total_pages > 1) {
      html = `Displaying <strong>${this.lowerValue} - ${this.upperValue}</strong> of <strong>${this.paginationValue.total_count}</strong> in total`;
    } else {
      html = '';
    }

    this.element.innerHTML = html;
  }
}
