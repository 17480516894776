import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static values = {
    id: Number
  }

  click(event) {
    Cookies.set('saved_search_id', this.idValue, { sameSite: 'strict' });
    Cookies.remove('search_params');
  }
}
