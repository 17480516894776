import { Controller } from "@hotwired/stimulus"
import mapboxgl from "!mapbox-gl"

export default class extends Controller {
  static values = {
    // Required
    mapboxApiKey: String,

    // Optional
    geojson: Object,
    style: { type: String, default: 'mapbox://styles/mapbox/streets-v12' },
    zoom: { type: Number, default: 12 },
  }

  static targets = ['container']

  map = null

  initialize() {
    mapboxgl.accessToken = this.mapboxApiKeyValue
  }

  connect() {
    this.element.classList.remove("d-none")

    if (this.hasGeojsonValue) {
      this.renderMap()
    }
  }

  addMarkers() {
    for (const feature of this.geojsonValue.data.features) {
      const [long, lat] = feature.geometry.coordinates
      const title = feature.properties.title
      const description = feature.properties.description

      let html = `<h3>${title}</h3>`

      if (!!description) {
        html += `<h4>${description}</h4>`
      }

      const popup = new mapboxgl.Popup().setHTML(html)

      new mapboxgl.Marker({
        color: "#22aefd",
        title: name,
      })
        .setLngLat({ lng: long, lat: lat })
        .setPopup(popup)
        .addTo(this.map)
    }
  }

  geojsonValueChanged() {
    if (this.hasGeojsonValue) {
      this.renderMap()

      this.containerTarget.classList.remove('d-none')
    }
  }

  firstCoordinates() {
    const features = this.geojsonValue.data.features;

    if (features.length > 0) {
      return features[0].geometry.coordinates
    }

    return undefined
  }

  fitBounds() {
    const coordinates = this.firstCoordinates()
    const bounds = new mapboxgl.LngLatBounds(coordinates, coordinates)

    for (const feature of this.geojsonValue.data.features) {
      bounds.extend(feature.geometry.coordinates)
    }

    this.map.fitBounds(bounds, {
      padding: 50
    })
  }

  moreThanOneFeature() {
    return this.geojsonValue.data.features.length > 1
  }

  reloadData({ detail: { meta: { geojson } } }) {
    this.geojsonValue = geojson
  }

  renderMap() {
    this.map = new mapboxgl.Map({
      center: this.firstCoordinates(),
      container: this.containerTarget,
      zoom: this.zoomValue,
      style: this.styleValue,
      trackResize: true,
    })

    this.map.addControl(new mapboxgl.NavigationControl())

    this.map.on('load', () => {
      // this.map.addSource('points', this.geojsonValue)

      this.addMarkers()

      if (this.moreThanOneFeature()) {
        this.fitBounds()
      }
    })
  }

  reset() {
    this.containerTarget.innerHTML = ''
  }
}
