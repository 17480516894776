import { Controller } from "@hotwired/stimulus"
import { NaicsCode } from "../lib/naics-code"
import { NumberFormatter } from "../lib/number-formatter"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [
    'allFilters',
    'flashMessage',

    // Search results
    'map',
    'results',
    'pageEntriesInfo',
    'paginator',
    'saveSearch',
    'saveSearchDescription',
    'saveSearchModal',
    'savedSearch',
    'savedSearchDescription',
    'searchTerm',

    // Search inputs
    'keyword',
    'fedWasteGenerator',
    'activityLocation',
    'zip',
    'radius',
    'shippingDescription',
    'federalWasteCode',
    'stateWasteCode',
    'containerType',
    'managementMethodCode',
    'naicsCode',
    'usedOil',

    // Actions
    'submit',
    'expandSwitch',
    'expandCheckbox',
  ]

  static values = {
    customerTrialExpiresOn: String,
    customerType: String,
    endDate: String,
    expandResults: Boolean,
    isPersisted: Boolean,
    keyword: String,
    loadOnConnect: Boolean,
    page: Number,
    results: Object,
    resultUrl: String,
    savedSearch: Object,
    saveSearchType: String,
    saveSearchUrl: String,
    searchParams: Object,
    setCookie: Boolean,
    startDate: String,
    url: String,
  }

  url
  error = false

  initialize() {
    this.url = new URL(this.urlValue)
  }

  connect() {
    if (this.hasKeywordTarget) {
      this.keywordTarget.focus()
    }

    this.updateDaterange()

    if (this.hasLoadOnConnectValue) {
      this.search()
    } else if (this.hasSavedSearchValue) {
      this.initSavedSearch()
      this.initSaveSearchModal()
    }
  }

  disconnect() {
    if (this.hasSaveSearchModalTarget) {
      $(this.saveSearchModalTarget).off('shown.bs.modal')
    }
  }

  // TODO: Move to standalone controller
  initSaveSearchModal() {
    $(this.saveSearchModalTarget).on('shown.bs.modal', function() {
      this.saveSearchDescriptionTarget.focus()
    }.bind(this))
  }

  // TODO: Move to standalone controller
  initSavedSearch() {
    if (!isEmpty(this.savedSearchValue) && !isEmpty(this.searchParamsValue)) {
      this.searchParamsValue = Object.assign(this.savedSearchValue.params, this.searchParamsValue)
    } else if (!isEmpty(this.savedSearchValue)) {
      this.searchParamsValue = this.savedSearchValue.params
    }
  }

  search(event) {
    if (event) {
      event.preventDefault()
    }

    this.updateSearchTerm()
    this.fetch()
  }

  changeLoadingText() {
    const text = [
      "Searching Database...",
      "Combining Results...",
      "Refining Data Sets...",
      "Loading Results..."
    ];

    const elem = $("#loading-text-msg");
    elem.removeClass("d-none")

    let counter = 0;
    const inst = setInterval(change, 2000);

    function change() {
      elem.html(text[counter])
      counter++

      if (counter >= text.length) {
        counter = 0
        clearInterval(inst)
      }
    }
  }

  renderLoading() {
    this.resultsTarget.innerHTML = `
      <div class="text-center my-2">
        <div class="loadingio-spinner-blocks-jlunoakkb99"><div class="ldio-1t9ln3okngl">
          <div style="left:19.76px;top:19.76px;animation-delay:0s"></div><div style="left:41.6px;top:19.76px;animation-delay:0.125s"></div><div style="left:63.440000000000005px;top:19.76px;animation-delay:0.25s"></div><div style="left:19.76px;top:41.6px;animation-delay:0.875s"></div><div style="left:63.440000000000005px;top:41.6px;animation-delay:0.375s"></div><div style="left:19.76px;top:63.440000000000005px;animation-delay:0.75s"></div><div style="left:41.6px;top:63.440000000000005px;animation-delay:0.625s"></div><div style="left:63.440000000000005px;top:63.440000000000005px;animation-delay:0.5s"></div>
        </div></div>
        <p id="loading-text-msg" class="d-none">Gathering Data...</p>
      </div>`

    this.changeLoadingText()
  }

  initialResults() {
    return `
      <div class="card bg-light pt-3 mb-2">
        <div class="card-body">
          <p id="default-message">Define your search criteria and click <b>Search</b> to see the results.</p>
        </div>
      </div>
    `
  }

  noResults() {
    return `
      <div class="card bg-light mb-2">
        <h2 class="card-header text-left">No results</h2>
        <div class="card-body">
          <p class="pt-3">
            Refine your search criteria and click <b>Search</b> to see the results.
          </p>
        </div>
      </div>
    `
  }

  errorResults() {
    return `
      <div class="card bg-light pt-3 mb-2">
        <div class="card-body">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bug mb-1" viewBox="0 0 16 16">
              <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
            </svg>
            <b>There was an error completing your search.</b><br />
            Please make sure your keyword search value, <strong>\`${this.keywordValue}\`</strong>, does not contain any special characters.
          </p>
        </div>
      </div>
    `
  }

  renderSaveSearch() {
    this.saveSearchTarget.classList.remove('d-none')
    this.savedSearchTarget.classList.add('d-none')
  }

  savedSearchValueChanged() {
    if (this.hasSavedSearchValue) {
      if (isEmpty(this.savedSearchValue)) {
        Cookies.remove('saved_search_id')
      } else if (this.hasSavedSearchDescriptionTarget) {
        this.savedSearchDescriptionTarget.innerHTML = this.savedSearchValue.description
      }
    }
  }

  renderSavedSearch() {
    this.saveSearchTarget.classList.add('d-none')
    this.savedSearchTarget.classList.remove('d-none')
  }

  keywordValueChanged() {
    if (this.hasKeywordTarget) {
      this.keywordTarget.value = this.keywordValue
      this.updateSearchParam('keyword', this.keywordValue)

      this.renderSearchTerm()
    }
  }

  renderSearchTerm() {
    if (this.hasKeywordTarget && isEmpty(this.keywordValue)) {
      this.searchTermTarget.innerHTML = ''
    } else {
      this.searchTermTarget.innerHTML = `Search Results for <strong>\`${this.keywordValue}\`</strong>`
    }
  }

  toAddress(address) {
    let _address = '';

    if (!!address.street_no) {
      _address += ` ${address.street_no}`;
    }

    if (!!address.street1) {
      _address += ` ${address.street1}`;
    }

    if (!!address.street2) {
      _address += `<br />${address.street2}`;
    }

    if (!!(address.city || address.state || address.zip)) {
      _address += `<br />${address.city}, ${address.state} ${address.zip}`;
    }

    if (!!address.country) {
      _address += `<br />${address.country}`;
    }

    return _address;
  }

  renderCard(item, index) {
    return `
      <div class="card shadow mb-2">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-5 text-left">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building mt-n1" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
              </svg>
              <a class="link-unstyled" href="${this.resultUrlValue}/${item.handler_id}" target="_blank">${item.handler_name}</a>
            </div>
            <div class="col-2 text-right">
              <span class="small font-weight-light">
                ${item.physical_address.city}, ${item.physical_address.state}
              </span>
            </div>
            <div class="col-2 text-left">
              <span class="small font-weight-light">
                <strong>Manifests:</strong> ${NumberFormatter.formatInteger(item.manifest_count)}
              </span>
            </div>
            <div class="col-2 text-left">
              <span class="small font-weight-light">
                <strong>Tons:</strong> ${NumberFormatter.formatFloat(item.quantity_tons)}
              </span>
            </div>
            <div class="col-1 text-right">
              <button class="btn btn-sm btn-light"
                      data-toggle="collapse"
                      data-target="#result_${index}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div id="result_${index}" class="card-body collapse ${this.expandResultsValue ? 'show' : ''}">
          <div class="row">
            <div class="col">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-heading mt-n1" viewBox="0 0 16 16">
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z"/>
                </svg> <strong>Handler ID</strong><br>
                ${item.handler_id}
              </p>
              <p>
                <a class="btn btn-outline-primary" target="_blank" href="${this.resultUrlValue}/${item.handler_id}">
                  View Full Report
                </a>
              </p>
            </div>

            <div class="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo mt-n1" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
              </svg> <strong>Location Address</strong><br>
              ${this.toAddress(item.physical_address)}
            </div>

            <div class="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person mt-n1" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              </svg> <strong>Contact</strong><br>
              ${item.contact_name || '<i class="text-muted">No name</i>'}<br>
              ${item.contact_title || '<i class="text-muted">No title</i>'}<br>
              ${item.contact_phone || '<i class="text-muted">No phone</i>'}<br>
              ${item.contact_email || '<i class="text-muted">No email</i>'}<br>
            </div>

            <div class="col">
              <strong>NAICS Codes</strong><br>
              ${this.buildNaicsCodeBadges(item.naics_codes) || '<i class="text-muted">None</i>'}
            </div>
          </div>
        </div>
      </div>
    `
  }

  renderRedactedCard(item, index) {
    return `
      <div class="card shadow mb-2">
        <div class="card-header border-0">
          <div class="row">
            <div class="col-5 text-left">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building mt-n1" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
              </svg>
              <a class="link-unstyled" href="${this.resultUrlValue}/${item.handler_id}" target="_blank">${item.handler_name}</a>
            </div>
            <div class="col-2 text-right">
              <span class="small font-weight-light">
                ${item.city}, ${item.state}
              </span>
            </div>
            <div class="col-2 text-left">
              <span class="small font-weight-light"
                    data-controller="tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Manifest data available to paid subscribers">
                <strong>Manifests:</strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill mt-n1 text-muted" viewBox="0 0 16 16">
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>
              </span>
            </div>
            <div class="col-2 text-left">
              <span class="small
                    font-weight-light"
                    data-controller="tooltip"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Volume data available to paid subscribers">
                <strong>Tons:</strong>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill mt-n1 text-muted" viewBox="0 0 16 16">
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>
              </span>
            </div>
            <div class="col-1 text-right">
              <button class="btn btn-sm btn-light"
                      data-toggle="collapse"
                      data-target="#result_${index}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-expand" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div id="result_${index}" class="card-body collapse ${this.expandResultsValue ? 'show' : ''}">
          <div class="row">
            <div class="col">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-card-heading mt-n1" viewBox="0 0 16 16">
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z"/>
                </svg> <strong>Handler ID</strong><br>
                ${item.handler_id}
              </p>
              <p>
                <a class="btn btn-outline-primary" target="_blank" href="${this.resultUrlValue}/${item.handler_id}">
                  View Full Report
                </a>
              </p>
            </div>

            <div class="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo mt-n1" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
              </svg> <strong>Location Address</strong><br>
              ${this.toAddress(item.physical_address)}
            </div>

            <div class="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person mt-n1" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              </svg> <strong>Contact</strong><br>
              <p class="mt-2">
                <a href="/checkouts/new" class="btn btn-sm btn-outline-primary">Subscribe to Unlock</a>
              <p>
            </div>

            <div class="col">
              <strong>NAICS Codes</strong><br>
              <p class="mt-2">
                <a href="/checkouts/new" class="btn btn-sm btn-outline-primary">Subscribe to Unlock</a>
              <p>
            </div>
          </div>
        </div>
      </div>
    `
  }

  resetPaging() {
    this.pageEntriesInfoTarget.innerHTML = ''
    this.paginatorTarget.innerHTML = ''
  }

  // TODO: Move to an importable lib
  buildNaicsCodeBadges(codes) {
    let html = ''

    if (typeof codes.forEach === "function") {
      NaicsCode.find(codes).forEach(item => {
        if (item) {
          html = html.concat(`
            <span class="badge badge-light"
                  title="${item.description}"
                  data-toggle="tooltip"
                  data-controller="tooltip">${item.code}</span>
          `)
        }
      })
    } else {
      return
    }

    return html
  }

  // TODO: Move results rendering to a separate JS controller
  renderCards() {
    let html = ''
    let data = this.resultsValue.data || []
    let pagination = this.resultsValue?.meta?.pagination
    let today = new Date()

    if (this.error) {
      // html = this.errorResults()
    } else if (data.length > 0) {
      data.forEach((item, index) => {
        let card

        switch (this.customerTypeValue) {
          case 'FreemiumCustomer':
            card = this.renderRedactedCard(item, index)
            break
          case 'TrialCustomer':
            let trialExpiresOn = new Date(this.customerTrialExpiresOnValue)

            if (trialExpiresOn >= today) {
              card = this.renderCard(item, index)
            } else {
              card = this.renderRedactedCard(item, index)
            }

            break
          default: // Wastebits User, SubscribedCustomer
            card = this.renderCard(item, index)
        }

        html = html.concat(card)
      })
    } else {
      if (isEmpty(pagination)) {
        html = this.initialResults()
      } else {
        html = this.noResults()
      }
    }

    $(this.resultsTarget).html(html).fadeIn(200)
    this.expandSwitchTarget.classList.remove('d-none')
  }

  expandResultsValueChanged() {
    let elements = this.resultsTarget.getElementsByClassName('collapse')

    for (const el of elements) {
      if (this.expandResultsValue) {
        el.classList.add('show')
      } else {
        el.classList.remove('show')
      }
    }
  }

  toggleExpandSwitch() {
    this.expandResultsValue = this.expandCheckboxTarget.checked
  }

  fetch(event) {
    if (event) {
      event.preventDefault()
    }

    let searchParams = {
      'start_date': this.startDateValue,
      'end_date': this.endDateValue
    }

    if (!isEmpty(this.keywordValue)) {
      searchParams['keyword'] = this.keywordValue
    }

    if (!isEmpty(this.pageValue)) {
      searchParams['page'] = this.pageValue
    }

    let body = {
      search: Object.assign(this.searchParamsValue, searchParams)
    }

    this.error = false

    this.resetPaging()
    this.renderLoading()
    this.hideMap()

    this.expandSwitchTarget.classList.add('d-none')

    if (this.hasSaveSearchTarget) {
      this.saveSearchTarget.classList.add('d-none')
    }

    this.dispatch('loading')

    fetch(this.url, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify(body)
    })
      .then(async (response) => {
        return response.json()
      })
      .then(results => {
        this.resultsValue = results

        if (this.hasKeywordTarget) {
          this.renderSearchTerm()
        }

        this.renderCards()

        // Mapbox will not honor height & width defined styles when `d-none` is applied
        if (this.resultsValue.data.length > 0) {
          this.showMap()
        }

        if (this.hasSaveSearchTarget && this.hasSavedSearchValue) {
          this.toggleSavedSearch()
          this.saveSearchTarget.classList.remove('d-none')
        }

        this.dispatch("results", { detail: this.resultsValue })
      })
      .catch(err => {
        console.debug('error', err)
        this.error = true
        // this.renderCards()
      })
  }

  hideMap() {
    if (this.hasMapTarget) {
      this.mapTarget.classList.add('d-none')
    }
  }

  showMap() {
    if (this.hasMapTarget) {
      this.mapTarget.classList.remove('d-none')
    }
  }

  page(event) {
    if (event) {
      event.preventDefault()
    }

    this.pageValue = event.detail.page
    this.updateSearchParam('page', this.pageValue)
  }

  update({ detail }) {
    let key, value
    let obj = {}

    if (detail) {
      key = snakeCase(detail.key)
      value = detail.selections
    } else {
      key = snakeCase(event.currentTarget.dataset.searchTarget)
      value = $(this[`${target}Target`]).val()
    }

    if (isEmpty(value)) {
      delete this.searchParamsValue.key
    } else {
      obj[key] = this.sanitizeValue(value)
      this.updateSearchParams(obj)
    }
  }

  sanitizeValue(value) {
    if (Array.isArray(value)) {
      return value.join(',')
    } else if (isNan(value) || value === undefined) {
      return ''
    }

    return value
  }

  selectionDismissed({ detail: { key, value, selections } }) {
    if (key === 'keyword') {
      this.keywordValue = undefined
    } else if (!!selections) {
      this.updateSearchParam(key, selections)
    } else {
      this.updateSearchParam(key, undefined)
    }

  }

  updateSearchTerm() {
    if (this.hasKeywordTarget) {
      this.keywordValue = this.keywordTarget.value
    }
  }

  updateZipRadius(event) {
    if (event) {
      event.preventDefault()
    }

    let zip = event.detail.zip
    let radius = event.detail.radius

    if (isEmpty(zip)) {
      this.searchParamsValue = Object.assign(this.searchParamsValue, {
        zip: '',
        radius: ''
      })
    } else if (zip.length === 5) {
      this.searchParamsValue = Object.assign(this.searchParamsValue, {
        zip: zip,
        radius: radius
      })
    }
  }

  isSavedSearchModified() {
    if (!isEmpty(this.savedSearchValue)) {
      // Compare objects without keyword, start_date and end_date
      let savedSearchParams = clone(this.savedSearchValue.params)
      delete savedSearchParams.keyword
      delete savedSearchParams.start_date
      delete savedSearchParams.end_date

      let searchParams = clone(this.searchParamsValue)
      // delete searchParams.keyword
      // delete searchParams.start_date
      // delete searchParams.end_date

      return !isEqual(savedSearchParams, searchParams)
    }
  }

  toggleSavedSearch() {
    if (!isEmpty(this.savedSearchValue)) {
      if (this.isSavedSearchModified()) {
        this.renderSaveSearch()
      } else {
        this.renderSavedSearch()
      }
    } else if (this.hasAppliedFilters()) {
      this.renderSaveSearch()
    }
  }

  clear(event) {
    if (event) {
      event.preventDefault()
    }

    if (this.hasSavedSearchValue) {
      this.savedSearchValue = {}
      this.saveSearchTarget.classList.add('d-none')
      this.savedSearchTarget.classList.add('d-none')
    }

    if (this.hasKeywordTarget && !isEmpty(this.keywordTarget.value)) {
      this.keywordValue = ''
    }

    this.resultsValue = {}

    this.searchParamsValue = {
      'start_date': this.startDateValue,
      'end_date': this.endDateValue,
    }

    Cookies.remove('saved_search_id')

    this.resetPaging()
    this.hideMap()

    if (this.hasLoadOnConnectValue) {
      this.search()
    } else {
      this.renderCards()
    }
  }

  appliedFilters() {
    return Object.entries(this.searchParamsValue)
                 .filter(item => {
                   return item[1] !== "" &&
                          item[1] != null &&
                          !/(start|end)_date/.test(item[0])
                 })
  }

  hasAppliedFilters() {
    return this.appliedFilters().length > 0
  }

  // TODO: Create global helper
  isValueEmpty(value) {
    if (/(string|object)/i.test(typeof value)) {
      return isEmpty(value)
    } else if (/(number|boolean)/i.test(typeof value)) {
      return isEmpty(value.toString())
    }

    return isEmpty(value)
  }

  _updateSearchParam(params, key, value) {
    if (params['codes']) {
      delete params['codes']
    }

    if (this.isValueEmpty(value)) {
      delete params[key]
    } else {
      switch(key) {
        case 'state_waste_code':
          value = uniq(value.split(',')).join(',')
          break
      }

      params[key] = value
    }

    return params
  }

  updateSearchParam(key, value) {
    let searchParams = { ...this.searchParamsValue }

    this._updateSearchParam(searchParams, key, value)

    this.searchParamsValue = searchParams
  }

  updateSearchParams(obj) {
    let searchParams = { ...this.searchParamsValue }

    Object.entries(obj).forEach(([key, value]) => {
      this._updateSearchParam(searchParams, key, value)
    })

    this.searchParamsValue = searchParams
  }

  searchParamsValueChanged() {
    if (this.isPersistedValue) {
      Cookies.set('search_params', JSON.stringify(this.searchParamsValue), { sameSite: 'strict' })
    }

    if (this.hasAppliedFilters()) {
      if (!isEmpty(this.savedSearchValue) && this.isSavedSearchModified()) {
        Cookies.remove('saved_search_id')
      }

      this.search()
    } else {
      this.renderCards()
    }
  }

  save(event) {
    if (event) {
      event.preventDefault()
    }

    let searchParams = {
      'start_date': this.startDateValue,
      'end_date': this.endDateValue
    }

    if (this.keywordValue) {
      searchParams['keyword'] = this.keywordValue
    }

    let params = {
      type: this.saveSearchTypeValue,
      description: this.saveSearchDescriptionTarget.value,
      search: Object.assign(this.searchParamsValue, searchParams)
    }

    const options = {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      }
    }

    fetch(this.saveSearchUrlValue, options)
      .then(async (response) => {
        return response.json()
      })
      .then((results) => {
        this.savedSearchValue = results
        $(this.saveSearchModalTarget).modal('hide')
        this.saveSearchDescriptionTarget.value = ''
        this.renderSavedSearch()

        this.dispatch("saved", {
          detail: {
            message: `Saved searches can be found under <strong>My Account >> <a href="${this.saveSearchUrlValue}">My Saved Searches</a></strong>.`
          }
        })
      }).catch(error => {
        console.debug('error', error)
        // TODO: Show error in modal
      })
  }

  updateDaterange(event) {
    if (event?.detail) {
      this.startDateValue = event.detail.startDate
      this.endDateValue = event.detail.endDate
    }

    this.updateSearchParams({
      start_date: this.startDateValue,
      end_date: this.endDateValue
    })
  }
}
