import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    description: String,
    endDate: String,
    keyword: String,
    page: Number,
    pagination: Object,
    params: Object,
    resultPath: String,
    results: Object,
    startDate: String,
    type: String,
    url: String,
  }

  static targets = [
    'keyword',
    'results',
    'pageEntriesInfo',
    'paginator',
  ]

  initialize() {
    this.url = new URL(this.urlValue)
    this.url.searchParams.set('page', this.pageValue)
    this.url.searchParams.set('start_date', this.startDateValue)
    this.url.searchParams.set('end_date', this.endDateValue)
    this.error = false
  }

  connect() {
    this.fetch()
  }

  search(event) {
    if (event) {
      event.preventDefault()
    }

    Turbo.visit(this.url)
  }

  fetch() {
    this.renderLoading()

    fetch(this.url, {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      }
    })
      .then(async (response) => {
        return response.json()
      })
      .then((results) => {
        this.resultsValue = results
        // this.renderTable()
        this.dispatch("results", { detail: this.resultsValue })
      })
      .catch((err) => {
        this.error = true
        this.renderTable()
      })
  }

  page(event) {
    if (event) {
      event.preventDefault()
    }

    this.pageValue = event.detail.page

    Turbo.visit(this.url)
  }

  changeLoadingText() {
    var text = ["Searching Database...", "Combining Results...", "Refining Data Sets...", "Loading Results..."]
    var counter = 0

    var elem = $("#loading-text-msg")
    elem.removeClass("d-none")

    var inst = setInterval(change, 2000)

    function change() {
      elem.html(text[counter])
      counter++
      if (counter >= text.length) {
        counter = 0
        clearInterval(inst)
      }
    }
  }

  renderLoading() {
    let html = '<tr> \
      <td colspan="6"> \
        <div class="text-center my-2"> \
          <div class="loadingio-spinner-blocks-jlunoakkb99"><div class="ldio-1t9ln3okngl"> \
            <div style="left:19.76pxtop:19.76pxanimation-delay:0s"></div><div style="left:41.6pxtop:19.76pxanimation-delay:0.125s"></div><div style="left:63.440000000000005pxtop:19.76pxanimation-delay:0.25s"></div><div style="left:19.76pxtop:41.6pxanimation-delay:0.875s"></div><div style="left:63.440000000000005pxtop:41.6pxanimation-delay:0.375s"></div><div style="left:19.76pxtop:63.440000000000005pxanimation-delay:0.75s"></div><div style="left:41.6pxtop:63.440000000000005pxanimation-delay:0.625s"></div><div style="left:63.440000000000005pxtop:63.440000000000005pxanimation-delay:0.5s"></div> \
          </div></div> \
          <p id="loading-text-msg" class="d-none">Gathering Data...</p>\
        </div> \
      </td> \
    </tr>'

    this.resultsTarget.innerHTML = html
    this.pageEntriesInfoTarget.innerHTML = ''
    this.paginatorTarget.innerHTML = ''

    this.changeLoadingText()
  }

  initialResults() {
    return `
      <tr>
        <td colspan="6">
          <p id="default-message" class="ml-2 mt-3">Define your search criteria and click <b>Search</b> to see the results.</p>
        </td>
      </tr>
    `
  }

  noResults() {
    return `
      <tr>
        <td colspan="6">
          <p class="ml-2 mt-3">
            <b>No results.</b><br />
            Refine your search criteria and click <b>Search</b> to see the results.
          </p>
        </td>
      </tr>
    `
  }

  errorResults() {
    return `
      <tr>
        <td colspan="7">
          <p class="ml-2 mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bug mb-1" viewBox="0 0 16 16">
              <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"/>
            </svg>
            <b>There was an error completing your search.</b>
          </p>
        </td>
      </tr>
    `
  }

  formatInteger(str) {
    return Number.parseInt(str).toLocaleString('en')
  }

  formatFloat(str) {
    return Number.parseFloat(str).toLocaleString('en', { maximumFractionDigits: 2 })
  }

  renderRow(item) {
    return `
      <tr>
        <td><a class="link-unstyled" href="${this.resultPathValue}/${item.handler_id}">${item.handler_name}</a></td>
        <td>${item.city || ''}</td>
        <td>${item.state}</td>
        <td>${item.zip}</td>
        <td>${this.formatInteger(item.manifest_count)}</td>
        <td>${this.formatFloat(item.quantity_tons)}</td>
      </tr>
    `
  }

  renderTable() {
    let tbody = ''

    if (isEmpty(this.resultsValue)) {
      tbody = this.initialResults()
    } else if (this.error) {
      tbody = this.errorResults()
    } else if (this.resultsValue.data.length > 0) {
      this.resultsValue.data.forEach(item => {
        tbody = tbody.concat(this.renderRow(item))
      })
    } else {
      if (isEmpty(this.paginationValue)) {
        tbody = this.initialResults()
      } else {
        tbody = this.noResults()
      }
    }

    let html = `
      <table name="results" class="table table-striped table-sm">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Zip</th>
            <th scope="col">Manifests</th>
            <th scope="col">Tons</th>
          </tr>
        </thead>
        <tbody class="align-middle">
          ${tbody}
        </tbody>
      </table>
    `

    $(this.resultsTarget).html(html).fadeIn(600)
  }

  resultsValueChanged() {
    this.renderTable()
  }

  updateKeyword(event) {
    if (event) {
      event.preventDefault()
    }

    this.keywordValue = this.keywordTarget.value
  }

  keywordValueChanged() {
    this.url.searchParams.set('keyword', this.keywordValue)
  }

  pageValueChanged() {
    this.url.searchParams.set('page', this.pageValue)
  }

  startDateValueChanged() {
    this.url.searchParams.set('start_date', this.startDateValue)
  }

  endDateValueChanged() {
    this.url.searchParams.set('end_date', this.endDateValue)
  }

  updateDaterange(event) {
    if (event) {
      event.preventDefault()
    }

    this.startDateValue = event.detail.startDate
    this.endDateValue = event.detail.endDate

    if (this.pageValue > 1) {
      this.pageValue = 1
    }

    Turbo.visit(this.url)
  }
}
