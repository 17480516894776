import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'annualCopy',
    'annualLabel',
    'annualSavings',
    'applyCoupon',
    'applyCouponInput',
    'applyCouponButton',
    'cardErrors',
    'coupon',
    'couponName',
    'couponId',
    'discount',
    'discountAmount',
    'monthlyLabel',
    'plan',
    'pricePerSeat',
    'removeDiscount',
    'seats',
    'subtotal',
    'subtotalContainer',
    'subscribe',
    'subscribeSpinner',
    'total',
    'totalMessage',
    'totalMessageContainer',
  ];

  static values = {
    checkoutPath: String,
    customerId: Number,
    plan: String,
    seats: Number,
    coupon: Object,
    stripeProductId: String,
    stripeParishableKey: String,
    monthlyPriceId: String,
    annualPriceId: String,
  };

  coupon;
  discount = 0;
  frequencySuffix; // `yr` or `mo`
  annualPerSeatAmount = 2388.00; // Price should be dynamically pulled in
  annualPerSeatMonthlyAmount = (this.annualPerSeatAmount / 12).toFixed(2);
  monthlyPerSeatAmount = 249.00; // Price should be dynamically pulled in

  // Calculations
  perSeatAmount;
  subtotal;
  total;

  // Stripe attributes
  cardElement;
  stripePriceId;

  connect() {
    // Must wait for StripJS to be loaded before initializing the `Stripe` object.
    document.body.addEventListener('stripe:loaded', this.initStripe.bind(this));

    this.applyCouponInputTarget.addEventListener('keyup', this.applyCouponInputHandler.bind(this));
    this.applyCouponButtonTarget.addEventListener('click', this.applyCouponButtonHandler.bind(this));
  }

  initStripe() {
    this.stripe = Stripe(this.stripeParishableKeyValue);

    var elements = this.stripe.elements();

    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    this.cardElement = elements.create("card", { style: style });
    this.cardElement.mount("#card-element");
    this.cardElement.on('change', this.showCardError.bind(this));
  }

  couponValueChanged() {
    this.calculateDiscount();
    this.calculateSubtotal();
    this.calculateTotal();

    this.render();
  }

  showCardError(event) {
    let displayError = document.getElementById('card-errors');

    if (event.error) {
      displayError.textContent = event.error.message;
      this.showError();
    } else {
      displayError.textContent = '';
      this.hideError();
    }

    this.hideSubscribingSpinner();
  }

  showCouponError(event) {
    this.applyCouponInputTarget.classList.add('is-invalid');
  }

  showSubscribingSpinner() {
    this.subscribeTarget.classList.add('d-none');
    this.subscribeSpinnerTarget.classList.remove('d-none');
  }

  hideSubscribingSpinner() {
    this.subscribeTarget.classList.remove('d-none');
    this.subscribeSpinnerTarget.classList.add('d-none');
  }

  showError() {
    this.cardErrorsTarget.classList.remove('d-none');
  }

  hideError() {
    this.cardErrorsTarget.classList.add('d-none');
  }

  createPaymentMethod(cardElement, customerId, priceId, seats, coupon) {
    return this.stripe.createPaymentMethod({ type: 'card', card: cardElement })
                 .then((result) => {
                    if (result.error) {
                      this.showCardError(result);
                    } else {
                      this.createSubscription({
                        customerId: customerId,
                        paymentMethodId: result.paymentMethod.id,
                        priceId: priceId,
                        seats: seats,
                        coupon: coupon
                      });
                    }
                 });
  }

  createSubscription({ customerId, paymentMethodId, priceId, seats, coupon }) {
    return (
      fetch(this.checkoutPathValue, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: JSON.stringify({
          customer_id: customerId,
          payment_method_id: paymentMethodId,
          price_id: priceId,
          seats: seats,
          coupon: coupon
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            // throw result;
            this.showCardError(result.error);
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        .then(this.handlePaymentThatRequiresCustomerAction.bind(this))
        .then(this.handleRequiresPaymentMethod.bind(this))
        .then(this.onSubscriptionComplete.bind(this))
        .catch(this.showCardError.bind(this))
    );
  }

  onSubscriptionComplete(result) {
    // Payment was successful.
    if (result.subscription.status === 'active') {
      Turbo.visit('/', { action: 'replace' });
    }
  }

  handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return this.stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId, })
                        .then((result) => {
                          if (result.error) {
                            this.showCardError(result);
                          } else {
                            if (result.paymentIntent.status === 'succeeded') {
                              // Show a success message to your customer.
                              // There's a risk of the customer closing the window before the callback.
                              // We recommend setting up webhook endpoints later in this guide.
                              return {
                                priceId: priceId,
                                subscription: subscription,
                                invoice: invoice,
                                paymentMethodId: paymentMethodId,
                              };
                            }
                          }
                        })
                        .catch((error) => {
                          this.displayError(error);
                        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId, }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: 'Your card was declined.' } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  isAnnual() {
    return this.planValue === "annual";
  }

  planValueChanged() {
    this.perSeatAmount = (this.isAnnual() ? this.annualPerSeatAmount : this.monthlyPerSeatAmount);
    this.stripePriceId = (this.isAnnual() ? this.annualPriceIdValue : this.monthlyPriceIdValue);
    this.frequencySuffix = (this.isAnnual() ? "yr" : "mo");

    this.calculateSubtotal();
    this.calculateDiscount();
    this.calculateTotal();

    if (this.isAnnual()) {
      this.calculateAnnualSavings();
    }

    this.render();
  }

  selectPlan(event) {
    if (event) {
      this.planValue = event.target.value;
    }
  }

  calculateAnnualSavings() {
    this.annualSavings = (this.monthlyPerSeatAmount * 12 * this.seatsValue) - (this.annualPerSeatAmount * this.seatsValue);

    if (this.annualSavings < 0) {
      this.annualSavings = 0;
    }
  }

  hasFixedDiscount() {
    if (this.hasCouponValue) {
      return !!this.couponValue.amount_off;
    }

    return false;
  }

  hasPercentDiscount() {
    if (this.hasCouponValue) {
      return !!this.couponValue.percent_off;
    }

    return false;
  }

  // The Stripe Ruby lib returns `amountOff` as a float, while their JS lib
  // returns it as an integer that needs to be divided by 100.
  calculateDiscount() {
    this.discount = 0;

    if (this.hasFixedDiscount()) {
      this.discount = (this.couponValue.amount_off / 100).toFixed(2);
    } else if (this.hasPercentDiscount()) {
      this.discount = (this.subtotal * (this.couponValue.percent_off_precise / 100)).toFixed(2);
    }
  }

  calculateSubtotal() {
    this.subtotal = (this.seatsValue * this.perSeatAmount).toFixed(2);
  }

  calculateTotal() {
    this.total = (this.subtotal - this.discount).toFixed(2);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Toggles the disabled attribute based on the input's value
  applyCouponInputHandler(event) {
    if (this.applyCouponInputTarget.value.length > 0) {
      this.couponValue.id = this.applyCouponInputTarget.value.toUpperCase();

      if (this.applyCouponButtonTarget.hasAttribute('disabled')) {
        this.applyCouponButtonTarget.removeAttribute('disabled');
      }
    } else {
      if (!this.applyCouponButtonTarget.hasAttribute('disabled')) {
        this.applyCouponButtonTarget.setAttribute('disabled', 'disabled');
      }
    }

    if (this.applyCouponInputTarget.classList.contains('is-invalid')) {
      this.applyCouponInputTarget.classList.remove('is-invalid');
    }
  }

  applyCouponButtonHandler(event) {
    if (event) {
      event.preventDefault();
    }

    let couponId = this.applyCouponInputTarget.value.toUpperCase();
    this.fetchCoupon(couponId);
  }

  fetchCoupon(couponId) {
    if (!this.hasStripeProductIdValue || isEmpty(couponId)) {
      return;
    }

    fetch(`/products/${this.stripeProductIdValue}/coupons/${couponId}`, {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      }
    })
      .then((response) => {
        return response.json();
      })
      // If the coupon is declined, display an error to the user.
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          // throw result;
          this.showCouponError(result.error);
        } else {
          this.couponValue = result;
        }
        return result;
      })
  }

  removeDiscount(event) {
    if (event) {
      event.preventDefault();
    }

    this.applyCouponInputTarget.value = '';
    this.couponValue = {};
  }

  renderPlan() {
    switch(this.planValue) {
      case 'annual':
        this.monthlyLabelTarget.classList.remove('active');
        this.annualLabelTarget.classList.add('active');
        break;
      case 'monthly':
        this.monthlyLabelTarget.classList.add('active');
        this.annualLabelTarget.classList.remove('active');
        break;
    }
  }

  renderPerSeatPricing() {
    if (this.isAnnual()) {
      this.pricePerSeatTarget.innerHTML = `<s></s>$${this.annualPerSeatMonthlyAmount}/mo`;
    } else {
      this.pricePerSeatTarget.innerHTML = `$${this.monthlyPerSeatAmount}/${this.frequencySuffix}`;
    }
  }

  renderDiscount() {
    if (this.discount > 0) {
      if (this.hasPercentDiscount()) {
        this.couponNameTarget.innerHTML = `${this.couponValue.name} (${this.couponValue.percent_off}%)`;
      } else {
        this.couponNameTarget.innerHTML = this.couponValue.name;
      }

      this.couponIdTarget.innerHTML = this.couponValue.id;

      switch (this.couponValue.duration) {
        case 'once':
          this.discountAmountTarget.innerHTML = `- $${this.numberWithCommas(this.discount)}`;
          this.totalMessageTarget.innerHTML = this.totalInitialMessage();
          this.totalMessageContainerTarget.classList.remove('d-none');

          break;
        case 'repeating':
          // TODO
          break;
        case 'forever':
          if (this.isAnnual()) {
            this.discountAmountTarget.innerHTML = `- $${this.numberWithCommas(this.discount)}`;
          } else {
            this.discountAmountTarget.innerHTML = `- $${this.numberWithCommas(this.discount)}`;
            this.totalMessageTarget.innerHTML = '';
            this.totalMessageContainerTarget.classList.add('d-none');
          }
          break;
      }

      this.discountTarget.classList.remove('d-none');
      this.removeDiscountTarget.classList.remove('d-none');
      this.applyCouponTarget.classList.add('d-none')
    } else {
      this.applyCouponInputTarget.innerHTML = '';
      this.couponNameTarget.innerHTML = '';
      this.couponIdTarget.innerHTML = '';
      this.discountAmountTarget.innerHTML = '';

      this.discountTarget.classList.add('d-none');
      this.removeDiscountTarget.classList.add('d-none');
      this.totalMessageContainerTarget.classList.add('d-none');
      this.applyCouponTarget.classList.remove('d-none')
    }
  }

  renderSubtotal() {
    this.subtotalTarget.innerHTML = `$${this.numberWithCommas(this.subtotal)}`;
  }

  renderTotal() {
    this.totalTarget.innerHTML = `$${this.numberWithCommas(this.total)}`;
  }

  renderPricingCopy() {
    if (this.isAnnual()) {
      this.annualCopyTarget.classList.remove('d-none');
      this.annualSavingsTarget.innerHTML = `$${this.numberWithCommas(this.annualSavings)}`;
    } else {
      this.annualCopyTarget.classList.add('d-none');
    }
  }

  render() {
    this.renderPlan();
    this.renderPerSeatPricing();
    this.renderSubtotal();
    this.renderDiscount();
    this.renderTotal();
    this.renderPricingCopy();
  }

  totalInitialMessage() {
    return `initial charge, then $${this.numberWithCommas(this.subtotal)}/${this.frequencySuffix} thereafter`;
  }

  updateSeats(event) {
    this.seatsValue = Number(event.srcElement.value);
  }

  seatsValueChange() {
    this.calculateSubtotal();
    this.calculateTotal();
    this.calculateAnnualSavings();

    this.renderSubtotal();
    this.renderTotal();
    this.renderPricingCopy();
  }

  subscribe(event) {
    if (event) {
      event.preventDefault();
    }

    this.showSubscribingSpinner();

    this.createPaymentMethod(
      this.cardElement,
      this.customerIdValue,
      this.stripePriceId,
      this.seatsValue,
      this.couponValue.id
    );
  }
}
