import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'hide' ]
  static targets = [ 'description', 'export', 'limit', 'modal' ]

  static values = {
    'description': String,
    'params': Object,
    'totalCount': Number,
    'type': String, // facility, generator, transporter
    'url': String
  }

  descriptionValueChanged() {
    if (this.hasDescriptionTarget) {
      this.descriptionTarget.value = this.descriptionValue
    }
  }

  paramsValueChanged() {
    if (isEmpty(this.paramsValue)) {
      this.hide()
    } else {
      this.show()
    }
  }

  totalCountValueChanged() {
    if (this.hasLimitTarget) {
      if (this.totalCountValue > 1000) {
        this.limitTarget.classList.remove(this.hideClass)
      } else {
        this.limitTarget.classList.add(this.hideClass)
      }
    }
  }

  clear() {
    if (this.hasDescriptionTarget) {
      this.descriptionValue = ''
    }

    this.paramsValue = {}
  }

  hide() {
    this.element.classList.add(this.hideClass)
  }

  save(event) {
    if (event) {
      event.preventDefault()
    }

    this.updateDescription();

    const body = {
      export: {
        description: this.descriptionValue,
        params: this.paramsValue,
        type: this.typeValue,
      }
    }

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        return response.json()
      })
      .then((results) => {
        $(this.modalTarget).modal('hide')

        this.dispatch("saved", {
          detail: {
            message: `Exports can be found under <strong>My Account &rsaquo; <a href="${this.urlValue}">My Exports</a></strong>.`
          }
        })
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  updateDescription() {
    if (this.hasDescriptionTarget) {
      this.descriptionValue = this.descriptionTarget.value
    }
  }

  show() {
    this.element.classList.remove(this.hideClass)
  }

  update({ detail: { data, meta } }) {
    if (data?.length > 0) {
      this.paramsValue = meta.search_params
      this.totalCountValue = meta.pagination.total_count
    }
  }
}
