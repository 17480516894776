import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    filter: String,
    key: String,
    selections: Array,
  };

  initialize() {
    this.filterValue = this.filter()
  }

  connect() {
    this.$element = $(this.element)

    this.$element.selectpicker({
      liveSearch: true
    })

    if (this.hasSelectionsValue) {
      this.$element.selectpicker('val', this.selectionsValue)
    }

    this.$element.on('changed.bs.select', this.changed.bind(this))
  }

  disable() {
    this.element.disabled = true
    this.$element.selectpicker('refresh')
  }

  enable() {
    this.element.disabled = false
    this.$element.selectpicker('refresh')
  }

  disconnect() {
    this.$element.off('changed.bs.select')
  }

  changed(event, _clickedIndex, _isSelected, _previousValue) {
    if (event) {
      event.preventDefault()
    }

    this.selectionsValue = this.$element.val()
    this.$element.selectpicker('toggle')

    this.dispatch('changed', {
      detail: {
        key: this.keyValue,
        selections: this.selectionsValue
      }
    })
  }

  refresh({ detail }) {
    if (detail.key === snakeCase(this.keyValue)) {
      let selections = clone(detail.selections) || ''
      this.$element.selectpicker('val', selections.split(','))
    }
  }

  clear(event) {
    event.preventDefault();

    if (!isEmpty(this.selectionsValue)) {
      this.$element.selectpicker('deselectAll')
      this.element.disabled = false
      this.$element.selectpicker('refresh')
    }
  }

  filter() {
    let filter;

    switch(this.keyValue) {
      case 'activityLocation':
        filter = 'state'
        break
      case 'federalWasteCode':
        filter = 'federalWasteCodes'
        break
      case 'stateWasteCode':
        filter = 'stateWasteCodes'
        break
      case 'containerType':
        filter = 'containerTypes'
        break
      case 'managementMethodCode':
        filter = 'disposalMethods'
        break
      case 'naicsCode':
        filter = 'naicsCodes'
        break
      case 'usedOil':
        filter = 'usedOil'
        break
    }

    return filter
  }

  toggleVisibility({ detail }) {
    let isFilterOn = detail.settings[this.filterValue]
    let isHidden = this.element.parentNode.classList.contains('d-none')

    if (isFilterOn && isHidden) {
      this.element.parentNode.classList.remove('d-none')
    } else if (!isFilterOn && !isHidden) {
      this.element.parentNode.classList.add('d-none')
    }
  }
}
