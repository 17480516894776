import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    zip: String,
    radius: String
  }

  static targets = ['zip', 'radius']

  defaultRadius = '50'

  updateZip(event) {
    if (event) {
      event.preventDefault()
    }

    this.zipValue = event.target.value

    if (isEmpty(this.zipValue)) {
      this.radiusValue = ''

      this.dispatchChangedEvent('zip')
    } else if (this.zipValue.length == 5 ) {
      if (isEmpty(this.radiusValue)) {
        this.radiusValue = this.defaultRadius
      }

      this.dispatchChangedEvent('zip')
    }
  }

  updateRadius(event) {
    if (event) {
      event.preventDefault()
    }

    this.radiusValue = event.target.dataset.radiusValue
    $(this.radiusTarget).selectpicker('toggle')

    this.dispatchChangedEvent('radius')
  }

  dispatchChangedEvent(key) {
    let selections

    if (!isEmpty(this.zipValue) && isEmpty(this.radiusValue)) {
      this.radiusValue = this.defaultRadius
    }

    switch(key) {
      case 'zip':
        selections = this.zipValue
        break;
      case 'radius':
        selections = this.radiusValue
        break;
    }

    let detail = {
      key: key,
      selections: selections,
      zip: this.zipValue,
      radius: this.radiusValue
    }

    this.dispatch('changed', { detail: detail })
  }

  radiusValueChanged() {
    let value = ''

    if (this.radiusValue.match(/^(0|exact)$/)) {
      value = "Exact"
    } else if (isEmpty(this.radiusValue)) {
      value = "Radius"
    } else {
      value = `${this.radiusValue} mi`
    }

    this.radiusTarget.innerHTML = value
  }

  zipValueChanged() {
    if (this.zipTarget.value != this.zipValue) {
      this.zipTarget.value = this.zipValue
    }

    if(!isEmpty(this.zipValue) && isEmpty(this.radiusValue)) {
      this.radiusValue = this.defaultRadius
    }
  }

  disable() {
    this.zipTarget.disabled = true
    this.radiusTarget.disabled = true

    $(this.radiusTarget).selectpicker('refresh')
  }

  enable() {
    this.zipTarget.disabled = false
    this.radiusTarget.disabled = false

    $(this.radiusTarget).selectpicker('refresh')
  }

  refresh({ detail }) {
    event.preventDefault()

    let key = detail.key.toLowerCase()
    let selections = detail.selections

    switch (key) {
      case 'zip':
        this.zipValue = selections

        if (isEmpty(this.radiusValue)) {
          this.radiusValue = this.defaultRadius
        }

        break;
      case 'radius':
        this.radiusValue = selections
        break;
      default:
        return
    }

    this.dispatch('refresh', {
      detail: {
        key: key,
        selections: selections,
        zip: this.zipValue,
        radius: this.radiusValue
      }
    })
  }

  clear() {
    this.zipValue = ''
    this.radiusValue = ''
  }

  toggleVisibility({ detail }) {
    let isFilterOn = detail.settings['zip']
    let isHidden = this.element.classList.contains('d-none')

    if (isFilterOn && isHidden) {
      this.element.classList.remove('d-none')
    } else if (!isFilterOn && !isHidden) {
      this.element.classList.add('d-none')
    }
  }
}
