import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "externalId", "trialExpiresOn", "type", "users", "firstName", "lastName",
    "email", "seats", "save"
  ]

  static values = {
    "type": String,
    "users": Array
  }

  emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  setType(event) {
    if (event) {
      event.preventDefault();
    }

    this.typeValue = this.typeTarget.value;
  }

  addUser(event) {
    if (event) {
      event.preventDefault();
    }

    let userParams = {
      "first_name": this.firstNameTarget.value,
      "last_name": this.lastNameTarget.value,
      "email": this.emailTarget.value
    };

    if (this.validUser(userParams)) {
      this.usersValue = this.usersValue.concat(userParams);

      this.firstNameTarget.value = '';
      this.lastNameTarget.value = '';
      this.emailTarget.value = '';

      this.emailTarget.classList.remove('is-invalid');
      this.firstNameTarget.focus();
    } else {
      this.emailTarget.classList.add('is-invalid');
    }
  }

  typeValueChanged() {
    this.typeTarget.value = this.typeValue;

    switch(this.typeValue) {
      case "TrialCustomer":
        this.trialExpiresOnTarget.disabled = false
        break;
      case "SubscribedCustomer":
        this.externalIdTarget.disabled = false
        break;
      default:
        this.trialExpiresOnTarget.disabled = true
        this.externalIdTarget.disabled = true
    }
  }

  validUser(user) {
    return this.emailRegexp.test(user.email);
  }

  removeUser(event) {
    if (event) {
      event.preventDefault();
    }

    let userIndex = Number(event.currentTarget.dataset.customerIndexParam);

    this.usersValue = this.usersValue.filter(function(user, index) {
      if (index !== userIndex) {
        return user;
      }
    });
  }

  usersValueChanged() {
    if (isEmpty(this.usersValue)) {
      this.renderNoUsers();
    } else {
      if (this.usersValue.length > 0) {
        this.seatsTarget.value = this.usersValue.length;
      }

      this.renderUsers();
    }
  }

  renderUsers() {
    let rows = [];

    this.usersValue.forEach((user, index) => {
      let row = `
        <tr>
          <td><input type="text" name="users_attributes[${index}][first_name]" value="${user['first_name']}" class="form-control" /></td>
          <td><input type="text" name="users_attributes[${index}][last_name]" value="${user['last_name']}" class="form-control" /></td>
          <td><input type="email" name="users_attributes[${index}][email]" value="${user['email']}" class="form-control" readonly="readonly" /></td>
          <td>
            <button class="btn btn-light"
              data-action="customer#removeUser"
              data-customer-index-param="${index}">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
              </svg>
            </button>
          </td>
        </tr>
      `;
      rows.push(row);
    });

    this.usersTarget.innerHTML = rows.join('');
    this.saveTarget.disabled = false;
  }

  renderNoUsers() {
    this.usersTarget.innerHTML = `
      <tr>
        <td colspan="4">
          No users.
        </td>
      </tr>
    `;

    this.saveTarget.disabled = true;
  }
}
