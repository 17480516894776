import { Controller } from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ['date']

  connect() {
    this.minDate = this.data.get("minDate");

    $(this.dateTarget).daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      autoApply: true,
      minDate: this.minDate,
      locale: {
        format: 'YYYY-MM-DD'
      }
    });

    $(this.dateTarget).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('YYYY-MM-DD'));
    });
  }

  disconnect() {
    $(this.dateTarget).off('apply.daterangepicker');
  }
}
