import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message']
  static values = { 'message': String }

  alert(event) {
    if (event) {
      event.preventDefault();
    }

    this.messageValue = event.detail.message;
  }

  messageValueChanged() {
    this.messageTarget.innerHTML = this.messageValue;

    if (isEmpty(this.messageValue)) {
      this.element.classList.add('d-none');
    } else {
      this.element.classList.remove('d-none');
    }
  }
}
