import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    stripeApiKey: String,
    stripeCustomerId: String,
    createPath: String,
    customerPath: String
  }

  static targets = [ 'cardErrors', 'submit', 'spinner' ]

  // Stripe card element
  cardElement

  connect() {
    // Must wait for StripJS to be loaded before initializing the `Stripe` object.
    document.body.addEventListener('stripe:loaded', this.initStripe.bind(this))
  }

  disconnect() {
    document.body.removeEventListener('stripe:loaded', this.initStripe.bind(this))
  }

  initStripe() {
    this.stripe = Stripe(this.stripeApiKeyValue)

    var elements = this.stripe.elements()

    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }

    this.cardElement = elements.create("card", { style: style })
    this.cardElement.mount("#card-element")
    this.cardElement.on('change', this.showCardError.bind(this))
  }

  handleCreatedPaymentMethod(response) {
    if (!!response.paymentMethod?.id) {
      this.attachPaymentMethodToCustomer(response.paymentMethod.id)
    }
  }

  handleError(response) {
    if (!response.paymentMethod?.id) {
      throw new Error(response.error.message)
    }

    return response
  }

  handleAttachedPaymentMethod(response) {
    if (!!response.paymentMethod?.id) {
      Turbo.visit(this.customerPathValue, { action: 'replace' });
    }
  }

  attachPaymentMethodToCustomer(paymentMethodId) {
    const data = {
      payment_method: {
        id: paymentMethodId
      }
    }

    return (
      fetch(this.createPathValue, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
        },
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(this.handleError.bind(this))
        .then(this.handleAttachedPaymentMethod.bind(this))
        .catch(this.showCardError.bind(this))
    )
  }

  hideError() {
    if (this.hasCardErrorsTarget) {
      this.cardErrorsTarget.classList.add('d-none')
    }
  }

  save(event) {
    if (event) {
      event.preventDefault()
    }

    this.showSpinner()

    return this.stripe.createPaymentMethod({ type: 'card', card: this.cardElement })
                      .then(this.handleError.bind(this))
                      .then(this.handleCreatedPaymentMethod.bind(this))
                      .catch(this.showCardError.bind(this))
  }

  showCardError(event) {
    let displayError = document.getElementById('card-errors')

    if (event.error) {
      displayError.textContent = event.error.message
      this.showError()
    } else {
      displayError.textContent = ''
      this.hideError()
    }

    this.hideSpinner()
  }

  showError() {
    if (this.hasCardErrorsTarget) {
      this.cardErrorsTarget.classList.remove('d-none')
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.submitTarget.classList.add('d-none')
      this.spinnerTarget.classList.remove('d-none')
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.submitTarget.classList.remove('d-none')
      this.spinnerTarget.classList.add('d-none')
    }
  }
}
