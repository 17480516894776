import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [
    'zip', 'state', 'federalWasteCodes', 'stateWasteCodes', 'containerTypes',
    'disposalMethods', 'naicsCodes', 'federalWasteGenerator', 'modal',
    'activeFilters', 'usedOil'
  ]

  static values = {
    url: String,

    // Settings
    zip: Boolean,
    state: Boolean,
    federalWasteCodes: Boolean,
    stateWasteCodes: Boolean,
    containerTypes: Boolean,
    disposalMethods: Boolean,
    naicsCodes: Boolean,
    federalWasteGenerator: Boolean,
    usedOil: Boolean,
  }

  currentSettings = {}
  settings = {}

  connect() {
    this.initSettingValues();
    this.renderSettingTargets();
    this.renderActiveFilters();
  }

  initSettingValues() {
    this.settings['zip'] = this.zipValue;
    this.settings['state'] = this.stateValue;
    this.settings['federalWasteCodes'] = this.federalWasteCodesValue;
    this.settings['stateWasteCodes'] = this.stateWasteCodesValue;
    this.settings['containerTypes'] = this.containerTypesValue;
    this.settings['disposalMethods'] = this.disposalMethodsValue;
    this.settings['naicsCodes'] = this.naicsCodesValue;
    this.settings['federalWasteGenerator'] = this.federalWasteGeneratorValue;
    this.settings['usedOil'] = this.usedOilValue;

    this.currentSettings = clone(this.settings);
  }

  renderSettingTarget(key) {
    let target = `${key}Target`;
    let value = `${key}Value`;

    if (this[value]) {
      this[target].checked = true;
    } else {
      this[target].checked = false;
    }
  }

  renderSettingTargets() {
    let keys = Object.keys(this.settings);

    keys.forEach(this.renderSettingTarget.bind(this));
  }

  activeFilters() {
    let collection = Object.entries(this.settings);

    return collection.filter(item => { return item[1] == true; })
  }

  renderActiveFilters() {
    let html = '';

    for (const [key, value] of this.activeFilters()) {
      html += `
        <button class="btn btn-primary btn-sm mr-2 mb-2" data-item="CO" aria-hidden="true" data-action="click->selections#dismiss" data-selections-target="selection" aria-label="Close">
          ${startCase(key)}
          <span class="ml-1 text-dark" data-filter="${key}" data-action="click->filters#dismiss">
            ×
          </span>
        </button>
      `.trim();
    }

    this.activeFiltersTarget.innerHTML = html;
  }

  dismiss(event) {
    if (event) {
      event.preventDefault();
    }

    let key = event.currentTarget.dataset.filter;
    let button = event.currentTarget.parentNode;
    let isChecked = false;
    let value = `${key}Value`;

    this.settings[key] = isChecked;
    this[value] = isChecked;

    button.remove();
    this.renderSettingTarget(key);

    this.dispatch('dismissed', {
      detail:  {
        key: key,
        value: isChecked
      }
    });
  }

  update(event) {
    if (event) {
      event.preventDefault();
    }

    let key = event.currentTarget.dataset.filtersTarget;
    let isChecked = event.currentTarget.checked;
    let value = `${key}Value`;

    this.settings[key] = isChecked;
    this[value] = isChecked;

    this.renderSettingTarget(key);
    this.renderActiveFilters();

    if (!isChecked) {
      this.clearCookie(key);
    }

    this.dispatch('updated', {
      detail: {
        key: key,
        value: isChecked
      }
    });
  }

  clearCookie(key) {
    let cookieKeys = [];

    switch (key) {
      case 'state':
        cookieKeys.push('activity_location');
        break;
      case 'zip':
        cookieKeys.push('zip');
        cookieKeys.push('radius');
        break;
      case 'federalWasteCodes':
        cookieKeys.push('federal_waste_code');
        break;
      case 'federalWasteGenerator':
        cookieKeys.push('fed_waste_generator');
        break;
      case 'stateWasteCodes':
        cookieKeys.push('state_waste_code');
        break;
      case 'containerTypes':
        cookieKeys.push('container_type');
        break;
      case 'disposalMethods':
        cookieKeys.push('management_method_code');
        break;
      case 'naicsCodes':
        cookieKeys.push('naics_code');
        break;
      case 'usedOil':
        cookieKeys.push('used_oil');
        break;
    }

    cookieKeys.forEach(function(cookieKey) {
      Cookies.remove(cookieKey);
    });
  }

  clearCookies(settings) {
    for (const [key, value] of Object.entries(settings)) {
      if (!value) {
        this.clearCookie(key);
      }
    }
  }

  closeModal() {
    $(this.modalTarget).modal('hide');
  }

  hasSettingsChanged() {
    return !isEqual(this.currentSettings, this.settings);
  }

  save(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.hasSettingsChanged()) {
      this.closeModal();
      return false;
    }

    fetch(this.urlValue, {
      method: 'put',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
      },
      body: JSON.stringify(this.settings)
    })
      .then(async (response) => {
        return response.json();
      })
      .then((results) => {
        this.currentSettings = clone(this.settings);
        this.clearCookies(results);

        this.dispatch("changed", {
          detail: {
            settings: this.settings
          }
        });

        this.closeModal();
      })
      .catch((err) => {
        this.error = true;
      })
  }

  cancel(event) {
    if (event) {
      event.preventDefault();
    }

    if (this.hasSettingsChanged()) {
      this.settings = clone(this.currentSettings);

      for (const [key, value] of Object.entries(this.settings)) {
        let item = `${key}Value`;
        this[item] = value;
      }

      this.renderSettingTargets();
    }

    this.closeModal();
  }
}
