import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'hidden', 'shown' ]
  static targets = [ 'clearAll', 'container' ]

  static values = {
    dismiss: Boolean,
    selections: Object
  }

  clear(event) {
    if (event) {
      event.preventDefault()
    }

    this.selectionsValue = {}

    this.dispatch('clear')
  }

  connect() {
    if (this.hasSelectionsValue) {
      this.render()
    }
  }

  dismiss(event) {
    if (event) {
      event.preventDefault();
    }

    let key = event.params.key
    let value = event.params.value;
    let selections = clone(this.filteredSelections())

    let filteredValues = selections[key].split(',')
                                        .filter(v => value.toString() !== v.toString())
                                        .join(',')

    if (isEmpty(filteredValues)) {
      delete selections[key]
    } else {
      selections[key] = filteredValues
    }

    this.selectionsValue = selections

    if (isEmpty(this.selectionsValue)) {
      this.dispatch('clear')
    } else {
      this.dispatch('dismissed', {
        detail: {
          key: key,
          value: value.toString(),
          selections: this.selectionsValue[key]
        }
      })
    }
  }

  dismissValueChanged() {
    if (this.dismissValue) {
      this.clearAllTarget.classList.remove(this.shownClass);
    } else {
      this.clearAllTarget.classList.add(this.hiddenClass);
    }
  }

  filteredSelections() {
    let _selections = clone(this.selectionsValue)

    delete _selections['start_date']
    delete _selections['end_date']

    for (const [k, v] of Object.entries(_selections)) {
      if (isEmpty(v)) {
        delete _selections[k]
      }
    } return _selections
  }

  hide() {
    this.containerTarget.innerHTML = '';
    this.element.classList.remove(this.shownClass)
    this.element.classList.add(this.hiddenClass)
  }

  update({ detail }) {
    if (isEqual(this.selectionsValue, detail.meta.search_params)) {
      this.render()
    } else {
      this.selectionsValue = detail.meta.search_params
    }
  }

  buildSelection(key, value) {
    let values = value.split(',')
    let items = []

    values.forEach(v => {
      let dismiss = ''

      if (this.dismissValue) {
        dismiss = `
          <span class="ml-1 text-dark"
                data-selections-key-param="${key}"
                data-selections-value-param="${v}"
                data-action="click->selections#dismiss">
            &times;
          </span>
        `
      }

      items.push(`
        <button class="btn btn-primary btn-sm mr-2 mb-2"
                data-item="${key}"
                aria-label="Close"
                aria-disabled="false">
          ${this.formatKey(key)}: ${this.formatValue(key, v)}
          ${dismiss}
        </button>
      `)
    })

    return items.join('')
  }

  render() {
    let selections = [];

    for (const [k, v] of Object.entries(this.filteredSelections())) {
      selections.push(
        this.buildSelection(k,v)
      )
    }

    if (selections.length > 0) {
      this.containerTarget.innerHTML = selections.join('')
      this.element.classList.add(this.shownClass)
      this.element.classList.remove(this.hiddenClass)
    } else {
      this.hide()
    }
  }

  formatKey(key) {
    let _key = clone(key);

    switch(_key) {
      case 'activity_location':
        _key = 'State'
        break
      case 'federal_waste_code':
        _key = 'Federal Code'
        break
      case 'state_waste_code':
        _key = 'State Code'
        break
      case 'management_method_code':
        _key = 'Disposal Method'
        break
      case 'naics_code':
        _key = 'NAICS Code'
        break
      default:
        _key = startCase(_key)
    }

    return _key;
  }

  formatValue(key, value) {
    let _value = clone(value);

    switch(key) {
      case 'fed_waste_generator':
        switch (value) {
          case '1':
            _value = 'LQG';
            break;
          case '2':
            _value = 'SQG';
            break;
          case '3':
            _value = 'VSQG';
            break;
        }

        break;
      case 'radius':
        if (value.match(/^(0|exact)$/)) {
          _value = 'Exact'
        } else {
          _value = `${value} mi`;
        }
        break;
      case 'used_oil':
        _value = startCase(_value.replace('used_oil_', ''));
        break;
    }

    return _value;

  }

  selectionsValueChanged() {
    this.render()
  }
}
