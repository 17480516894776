import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    type: String
  }

  initialize() {
    this.url = new URL(this.urlValue);
  }

  change(event) {
    if (event) {
      event.preventDefault();
    }

    this.typeValue = this.element.value
    this.url.searchParams.set('type', this.typeValue);

    Turbo.visit(this.url, { action: "replace" });
  }
}
